import React from "react";
import classes from "./Footer.module.scss";
import img1 from "../../../assets/img/header/logo_white.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.footer}>
      <div className={["container", classes.cont].join(" ")}>
        <div className={classes.footerBody}>
          <div className={classes.footerRow}>
            <div className={classes.footerRowLeft}>
              <img src={img1} alt="" />
            </div>
            <div className={classes.footerRowRight}>
              <div className={classes.footerRowRightTop}>
                <div className={[classes.footerRowRightTopLeft].join(" ")}>
                  {t("footer_top_1")}
                </div>
                <a
                  href="tel:441241340121"
                  className={[
                    classes.footerRowRightTopRightPhone,
                    "font-16",
                  ].join(" ")}
                >
                  +441241340121
                </a>
                <a
                  href="mailto:support@profit-connect.io"
                  className={[
                    classes.footerRowRightTopRightEmail,
                    "font-16",
                  ].join(" ")}
                >
                  support@profit-connect.io
                </a>
              </div>
              <div className={classes.footerRowRightCenter}>
                <div className={classes.footerRowRightCenterLeft}>
                  <a
                    href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank"
                    className={[
                      classes.footerRowRightCenterLeftItem,
                      "font-16",
                    ].join(" ")}
                  >
                    {t("footer_center_11")}
                  </a>
                  <a
                    href="/docs/PRIVACY_POLICY.pdf" target="_blank"
                    className={[
                      classes.footerRowRightCenterLeftItem,
                      "font-16",
                    ].join(" ")}
                  >
                    {t("footer_center_12")}
                  </a>
                  <a
                    href="/docs/AML_POLICY.pdf" target="_blank"
                    className={[
                      classes.footerRowRightCenterLeftItem,
                      "font-16",
                    ].join(" ")}
                  >
                    {t("footer_center_13")}
                  </a>
                </div>
                <div className={classes.footerRowRightCenterCenter}>
                  <div className={classes.footerRowRightCenterCenterTitle}>
                    {t("footer_center_21")}
                  </div>
                  <div className={classes.footerRowRightCenterCenterText}>
                    {t("footer_center_22")}
                  </div>
                </div>
                <div className={classes.footerRowRightCenterRight}>
                  <div className={classes.footerRowRightCenterRightTitle}>
                    {t("footer_center_31")}
                  </div>
                  <div className={classes.footerRowRightCenterRightText}>
                    {t("footer_center_32")}
                  </div>
                  <div className={classes.footerRowRightCenterRightText}>
                    {t("footer_center_33")}
                  </div>
                </div>
              </div>
              <div className={classes.footerRowRightBottom}>
                <div
                  className={[classes.footerRowRightCopyright, "font-16"].join(
                    " "
                  )}
                >
                  {t("footer_bottom_1")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
